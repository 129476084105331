
















import { Component, Mixins, Prop } from 'vue-property-decorator'

import { CarParksResource, CarsResource } from '@/store/types'
import SystemMixin from '@/mixins/SystemMixin'


/**
 * Это карточка выбора автомобиля или автопарка (рабочей сущности)
 * Названия по лучше я не смог придумать
 */
@Component({})
export default class EntityCard extends Mixins(SystemMixin) {
  @Prop({ required: true })
  readonly card!: CarParksResource | CarsResource | any
}
