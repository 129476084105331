
















import { Component, Prop, Vue } from 'vue-property-decorator'

import { NameValueResource } from '@/store/types'

@Component({})
export default class RoleCard extends Vue {
  @Prop({ required: true })
  readonly card!: NameValueResource

  @Prop({ default: true })
  readonly isOneRole!: boolean
}
