

















































// CORE
import { Component, Mixins, Watch } from 'vue-property-decorator'

// COMPONENTS
import RoleSelectionCard from '@/components/cards/RoleSelectionCard.vue'
import IntermarkLabel from '@/components/IntermarkLabel.vue'
import Sidebar from '@/components/Sidebar.vue'

// INTERFACES
import { CarParksResource, CarsResource, NameValueResource } from '@/store/types'

// STORE
import ProfileModule from '@/store/modules/profile'
import ReportsModule from '@/store/modules/reports'
import FleetReportsModule from '@/store/modules/fleet/reports'
import DriverReportsModule from '@/store/modules/driver/driver-reports'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    RoleSelectionCard,
    IntermarkLabel,
    Sidebar,
  },
})
export default class EntitySelection extends Mixins(SystemMixin, NotifyMixin) {
  private get isEntityChangePage (): boolean {
    return this.$route.name === 'role.entity'
  }

  private get items (): CarParksResource[] | CarsResource[] | any {
    if (this.role === 'fleet') {
      return ProfileModule.userResource.carParks
    } else if (this.role === 'mechanic') {
      return ProfileModule.userResource.carGarages
    } else if (this.role === 'driver') {
      return ProfileModule.userResource.cars
    } else {
      return []
    }
  }

  private get isNoComeBack (): boolean {
    return !!this.$route.params.noBtnBack
  }

  private get redirectPath (): string {
    return this.$route.query.redirect as string
  }

  private created () {
    if (this.isEntityChangePage) {
      if (this.items?.length === 1 && this.items[0]) {
        this.$router.push({
          name: this.redirectPath ? this.redirectPath : 'main',
          params: { entity: this.items[0].id.toString() },
        })
      }
    }

    // Обработчик события для запуска 'Reports update module'
    this.$bus.$on('startReportGenerating', this.handleGeneratingStart.bind(this))
  }

  private handleChooseItem (item: CarParksResource | CarsResource) {
    this.$router.push({
      name: this.$route.query.redirect ? this.$route.query.redirect as string : 'main',
      params: {
        role: this.role,
        entity: item.id.toString(),
      },
    })
  }

  // ****************************************** Reports update module ******************************************
  private isVisibleSnake = false
  private timerId = 0

  // Id отчетов на формировании
  private reportsIds: number[] = []
  private reportsLinks: NameValueResource[] = []

  // Есть ли формирующиеся отчёты
  private get isReportNotFormedYet (): boolean {
    return this.reportsIds.length > 0
  }

  private get allReports () {
    if (this.role === 'fleet') {
      return FleetReportsModule.allReports
    } else {
      return DriverReportsModule.allReports
    }
  }

  private async fetchReports () {
    if (this.role === 'fleet') {
      await FleetReportsModule.fetchReports({ carParkId: +this.entity })
    } else {
      await DriverReportsModule.fetchReports({ carId: +this.entity })
    }
  }

  private updateReports () {
    this.fetchReports()

    const filteredReports = this.allReports.filter(({ id }) => this.reportsIds.includes(id))

    if (filteredReports.length) {
      filteredReports.map(({ status, id, name }) => {
        if (status.toLowerCase() === 'сформировано') {
          this.isVisibleSnake = true
          this.deleteId(id)

          this.fetchReports()

          ReportsModule.fetchReport(id)
            .then(res => {
              this.reportsLinks.push({
                name: res.name,
                value: res.file.url,
              })
            })
        } else if (status.toLowerCase() === 'ошибка') {
          this.deleteId(id)

          this.notifyError(`Во время формирования отчёта "${name}" произошла ошибка.`)
        }
      })
    } else if (this.timerId) {
      if (this.reportsIds.length) {
        this.reportsIds = []
      }

      clearInterval(this.timerId)
      this.timerId = 0
    }
  }

  private handleGeneratingStart (id: number) {
    this.reportsIds.push(id)
    this.updateReports()

    if (!this.timerId) {
      this.startIntervalUpdate()
    }
  }

  private deleteId (reportId: number) {

    const index = this.reportsIds.findIndex(id => id === reportId)
    if (index > -1) {
      this.reportsIds.splice(index, 1)
    }
  }

  private startIntervalUpdate () {
    this.timerId = setInterval(
      this.updateReports.bind(this),
      5000,
    )
  }

  private handleCloseSnack () {
    this.isVisibleSnake = false
    this.reportsLinks = []
  }

  private beforeDestroy () {
    clearInterval(this.timerId)
  }

  @Watch('isReportNotFormedYet')
  private isReportNotFormedYetWatcher () {
    if (this.isReportNotFormedYet) {
      if (!this.timerId) {
        this.startIntervalUpdate()
      }
    } else {
      clearInterval(this.timerId)
      this.timerId = 0
    }
  }
}
