























import { Component, Mixins, Prop } from 'vue-property-decorator'

// COMPONENTS
import DrawerHeader from '@/components/drawers/DrawerHeader.vue'
import EntityCard from '@/components/cards/EntityCard.vue'
import RoleCard from '@/components/cards/RoleCard.vue'

// INTERFACES
import { INameValueItem, NameValueResource } from '@/store/types'

// STORE
import ProfileModule from '@/store/modules/profile'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

@Component({
  components: {
    DrawerHeader,
    EntityCard,
    RoleCard,
  },
})
export default class RoleSelectionCard extends Mixins(SystemMixin) {
  @Prop({ default: () => ([]) })
  readonly items!: INameValueItem[]

  @Prop({ default: true })
  readonly isBackButton!: boolean

  @Prop({
    default: 'role',
    validator (value: string) :boolean {
      return !!value.match(/(role|item)/)
    },
  })
  readonly mod!: string

  private get heading (): string {
    if (this.role?.length) {
      return this.role === 'driver' ? 'Выбор автомобиля' : 'Выбор автопарка'
    } else {
      return 'Выбор профиля'
    }
  }

  private get roles (): NameValueResource[] {
    let result: NameValueResource[] = []

    if (ProfileModule.userResource?.carParks?.length > 1) {
      result.push({
        name: 'fleet',
        value: 'multi',
      })
    } else if (ProfileModule.userResource?.carParks?.length) {
      result.push({
        name: 'fleet',
        value: ProfileModule.userResource.carParks[0].partnerNumber,
      })
    }

    if (ProfileModule.userResource?.carGarages?.length > 1) {
      result.push({
        name: 'mechanic',
        value: 'multi',
      })
    } else if (ProfileModule.userResource?.carGarages?.length) {
      result.push({
        name: 'mechanic',
        value: ProfileModule.userResource.carGarages[0].partnerNumber,
      })
    }

    if (ProfileModule.userResource?.cars?.length > 1) {
      result.push({
        name: 'driver',
        value: 'multi',
      })
    } else if (ProfileModule.userResource?.cars?.length) {
      result.push({
        name: 'driver',
        value: ProfileModule.userResource.cars[0].vin,
      })
    }

    return result
  }
}
